import React from "react"
import { Row, Col, Button } from "antd"
import { RightOutlined, WarningFilled } from "@ant-design/icons"

const data = ["TOP", "企業情報", "退会手続き申請"]

export default function WithDrawMoney() {
  return (
    <div>
      <div className="flex items-center mt-3">
        {data.map((item, index) => (
          <span key={index} className="flex items-center">
            <span className="mx-2.5">{item}</span>
            {index !== data.length - 1 && <RightOutlined />}
          </span>
        ))}
      </div>
      <div className="mt-10">
        <Row>
          <Col
            span={14}
            offset={5}
            style={{
              backgroundColor: "#fff",
            }}
          >
            <div className="flex flex-col items-center p-20">
              <WarningFilled style={{ fontSize: "2.5rem", color: "#EA6D69" }} />
              <h2
                style={{
                  color: "#EA6D69",
                  fontSize: "22px",
                }}
              >
                退会手続きの前にご確認ください。
              </h2>
              <p
                className="text-center pb-6"
                style={{
                  borderBottom: "1px solid #ccc",
                }}
              >
                会員を退会された場合には、現在マイページに設定している情報や各種取引履歴がすべて削除されます。
                そのため、取引を再開される場合には再度会員情報のご入力が必要になりますのでご了承ください。
                また、取引中の商品がある場合には退会出来ません。
                取引完了後に改めて退会手続きを行ってください。
              </p>
              <Button
                type="primary"
                className="mt-16"
                style={{
                  width: "20rem",
                  height: "2.5rem",
                }}
              >
                企業情報に戻る
              </Button>
              <Button
                type="primary"
                danger
                className="mt-7"
                style={{
                  width: "20rem",
                  height: "2.5rem",
                }}
              >
                会員退会手続きへ
              </Button>
              <div
                className="mt-4"
                style={{
                  color: "#E60020",
                }}
              >
                TOPページへ
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
